<script>
import { defineComponent } from 'vue';
import Home from '../Home.vue';

export default defineComponent({
  components: {
    Home,
  },
});
</script>

<template>
  <home overrideSlug="project-nova" />
</template>

<style lang="scss">
@import '@/assets/stylesheets/colors.scss';

:root {
  --gray-0: #152b3d;
  --gray-1: #e5c09c;
  --gray-2: #f4ddb5;

  --high-em: #d14b39;
  --medium-em: #d14b39;
  --disabled: #d14b39;

  --success: #4caf50;
  --danger: #d14b39;
}

body {
  background: url('https://i.imgur.com/0FiGJAQ.jpg');
  background-size: cover;
}

button {
  border-color: $high-em !important;
  color: $high-em !important;
}

.button--success,
.button--success:hover {
  background: none;
}

.container {
  border: 1em solid $gray-2;
}

.header {
  background-color: $gray-0;
}

.command {
  background: none;
}

.tooltip__text {
  background-color: $gray-0;
}

.modal__container {
  background-color: rgba(255, 255, 255, 0.38);
}

.modal {
  background-color: $gray-0;
}

footer,
.footer__anchor {
  color: $gray-2;
}
</style>
